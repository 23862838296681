import React, { Component } from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/**
 * 吹き出しを表示するボタンを表示
 */
export default class SetButtonWithSpeechBubble extends Component {
    render() {
        const speechBubbleData = (
            <Tooltip id="tooltip">
                {this.props.speechBubble}
            </Tooltip>
        );
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={speechBubbleData}
                delayShow={300}>
                <Button
                    size={this.props.size}
                    className={this.props.className}
                    onClick={this.props.onClick}
                >
                    <FontAwesomeIcon icon={this.props.DisplayIcon}/>
                </Button>
            </OverlayTrigger>
        );
    }
}
