import React, {Component} from "react";
import axios from "axios";
import moment from "moment";
import params from "../AscParams.json";
import selectOption from "../containers/Elements/SelectOption/SelectOption";
import * as GlobalConst from "./AscConstants";



const b64DecodeUnicode = str => {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
};

const blockUI = () => {
    let blockDiv = document.getElementById("asc-block-ui");

    if (blockDiv) {
        blockDiv.classList.remove("asc-display-none");
    }
}

const unblockUI = () => {
    let blockDiv = document.getElementById("asc-block-ui");

    if (blockDiv) {
        blockDiv.classList.add("asc-display-none");
    }
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = params.baseURL[window.location.hostname];
axios.defaults.timeout = 60 * 1000;

axios.interceptors.response.use(res => {
    if (typeof res.data === "string") {
        try {
            let base64DecodingData = JSON.parse(b64DecodeUnicode(res.data));
            res.data = base64DecodingData;
        } catch (e) {}
    }

    unblockUI();
    return res;
}, err => {
    try {
        if (typeof err.response.data === "string") {
            try {
                let base64DecodingData = JSON.parse(b64DecodeUnicode(err.response.data));
                err.response.data = base64DecodingData;
            } catch (e) {}
        }
    } catch (err) {}

    if ((err.status && err.status === 401) || (err.response && err.response.status && err.response.status === 401)) {
        alert('ログアウトされました。');
        window.location.reload();
    }

    unblockUI();
    return Promise.reject(err);
});

export default class AscComponent extends Component {
    constructor(props) {
        super(props);

        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.table = React.createRef();
        this.baseURL = params.baseURL[window.location.hostname];
        this.onTextChange = this.onTextChange.bind(this);
        this.onTextChange_Limit = this.onTextChange_Limit.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onMultiSelectChange = this.onMultiSelectChange.bind(this);
        this.onNestSetState = this.onNestSetState.bind(this);
        this.onNestCheckBoxChange = this.onNestCheckBoxChange.bind(this);
        this.getFormatSec = this.getFormatSec.bind(this)
        this.fetchData = this.reactTableFetchData;
        this.blockUI = blockUI;
        this.unblockUI = unblockUI;
        if (props.location) {
            this.reactContainerPath = props.location.pathname;
            this.reactTableTarget = props.location.pathname + "/board";
        }
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    propSetState = state => {
        this.setState(state);
    }

    onTextChange (event, param) {
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        this.setState({[param]: value});
    }

    onTextChange_Limit (event,param,limitcount) {
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        if(value.length <= limitcount)
        {
            this.setState({[param]: value});
        }
    }

    onSelectChange (event, param) {
        let value = event && event.value ? event && event.value : "";
        this.setState({[param]: value});
    }

    onMultiSelectChange (event, param) {
        if (Array.isArray(event)) {
            this.setState({[param]: event.map(row => {return row.value})});
        }
    }

    /**
     * stateでネストされている値を変更する
     * param キー
     * param2 ネストされているキー
     */
    onNestSetState (event, param, param2) {
        this.setState({ [param]: { ...this.state[param], [param2]: event.target.value}});
    }

    /**
     * 権限管理
     * stateでネストされているチェックボックスの値を切り替える
     */
    onNestCheckBoxChange (event, param, param2) {
        if (param2 === "read" && this.state[param][param2]) {
            // 閲覧がfalseになった場合は全てfalse
            this.setState({[param]: { ...this.state[param],
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                    download: false,
                    playback: false
                }});
        } else if (param2 !== "read" && !this.state[param][param2]) {
            // 何かがtrueになった場合は閲覧も必ずtrue
            this.setState({[param]: { ...this.state[param],
                    [param2]: true,
                    read: true
                }});
        } else {
            this.setState({[param]: { ...this.state[param], [param2]: !this.state[param][param2]}});
        }
    };
    
    escapeHtml(unsafe) {
        //アンダーバー対応
        return unsafe.replace('_', '\\_'); 
    }

    onFilterChange = (value, accessor) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === accessor);
    
        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }
        value = this.escapeHtml(value);
    
        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: accessor,
                value    
            });
        }
        
        this.setState({
            filtered: my_filtered,
        });
    
        setTimeout(() => {
            this.reactTableRefresh();
        }, 300);
    }

    getMomentTime = ({lag = 9, formatStr = "h", format = "YYYY-MM-DD", date}) => {
        return moment(date).utc().add(lag, formatStr).format(format);
    };

    getFormatSec(sec){
        let hours = Math.floor(sec/(60*60));
        let minutes = Math.floor((sec%(60*60))/(60));
        let seconds = ((sec%(60*60))%(60));
        return moment({hours , minutes, seconds}).format("HH:mm:ss")
    }

    sprintf = (message, ...args) => {
        args.forEach((arg, key) => {
            message = message.replace(new RegExp("\\{" + key + "\\}", "gi"), arg);
        });

        return message;
    };

    /**
     * エラーメッセージの取得
     * @param {string} code Message.jsで定義したメッセージコードを合わせる
     * @param {array} 
     * @returns {string} メッセージ文言
     */
    getErrorString = ({code, args}) => {
        let msg = ''
        args = args || [];

        if (this.props.langText.Message[code]) {
            msg = this.sprintf(this.props.langText.Message[code], ...args);
        }
        
        return msg
    }

    getSelectOption = (column_name, lang) => selectOption(column_name, lang);

    ascAxios = async (method, ...arg) => {
        return await axios[method](...arg);
    };

    reactTableFetchData = async(pageSize, pageIndex, sorted, filtered) =>{
        await this.setState({loading: true, blocking: true});
        if (this.reactTableTarget) {
            try {
                let res = await this.ascAxios("post", this.reactTableTarget, {
                    page: pageIndex,
                    pageSize: pageSize,
                    sorted: sorted,
                    filtered: filtered,
                });
                // findAndCountAll 内で group by を使うと、.count が array で返ってくるバグがある。
                // .count を length で計算し直すことにより、board のページングが正常に作動する。
                let count =0;
                if (Array.isArray(res.data.count)){
                    count =  res.data.count.length;
                } else {
                    count = res.data.count
                }
                let data = res.data.rows ? res.data.rows : res.data;
                
                await this.setState({
                    data,
                    pageSize,
                    pageIndex,
                    pageCount: Math.ceil(count / pageSize),
                    count
                })
                
            } catch (e) {
                console.error(e)
                await this.setState({
                    data: [],
                    pages: null,
                    count: null
                });
                if(e.response && e.response.data.code){
                    this.showErrorObjectMessage(e, "GetDataError_Server");
                } else if (!(e.status && e.status === 401) && !(e.response && e.response.status && e.response.status === 401)) {
                    this.showErrorObjectMessage(e, "GetDataError_Server");
                }
            } finally {
                await this.setState({
                    loading: false,
                    blocking: false
                });
            }
        }
    }

    reactTableRefresh = async(update_flag = false) => {
        let {
            pageSize,
            pageIndex,
            sorted,
            filtered
        } = this.state;

        if(!update_flag){
            pageIndex = 0;
        }
        this.reactTableFetchData( pageSize, pageIndex, sorted, filtered);
    }

    /**
     * 自身の権限範囲が指定のあった権限範囲以上か判定する
     * @return {Boolean}
     */
    getLevelGreaterEqual = (compareObject) => {
        if (this.props.currentPermission.level) {
            const myScope = this.props.currentPermission.level;
            return GlobalConst.LEVEL_OBJECT[myScope].value <= compareObject.value;
        } else {
            return false;
        }
    }

    /**
     * 自身の権限範囲が指定のあった権限範囲以下か判定する
     * @return {Boolean}
     */
    getLevelLessThanEqual = (compareObject) => {
        if (this.props.currentPermission.scope_code) {
            const myScope = this.props.currentPermission.scope_code;
            return GlobalConst.LEVEL_OBJECT[myScope].value >= compareObject.value;
        } else {
            return false;
        }
    }

    /**
     * scope_codeがsystemの場合は一覧に会社を表示
     * @param {Array} displayDataList 一覧表示データリスト
     * @param {Array} ArrayData 追加するデータ
     * @param {Number} addPosition 追加する位置
     * @return {Array}
     */
    displayByPermission = (displayDataList, ArrayData, addPosition) => {
        if (this.getLevelGreaterEqual(GlobalConst.LEVEL_OBJECT.system)) displayDataList.splice(addPosition, 0, ArrayData);
        return displayDataList;
    }

    /**
     * エラーメッセージの作成
     * @param {{message: string}} error エラーオブジェクト
     * @param {*} defaultMessage デフォルトメッセージ
     */
    showErrorObjectMessage = (error, defaultMessage = "ProcessingFailedError") => {
        let errorMessage = "";

        if (error.response &&
            error.response.data &&
            (error.response.data.code || error.response.data.message)) {
            errorMessage = error.response.data.code || error.response.data.message;
            //サーバーで発生したエラーメッセージを出力(エラーオブジェクト対応)
        } else if (error.message) {
            errorMessage = error.message;
            //クライアントで発生したエラーメッセージを出力(エラーオブジェクト対応)
        } else if (typeof error === "string") {
            //文字列エラーを出力
            errorMessage = error;
        } else {
            errorMessage = defaultMessage;
        }
        alert(
            this.props.langText.Message[errorMessage] || this.props.langText.Message[defaultMessage]
        );
    }

    getUserIds = async() => {
        try {
            let res = await this.ascAxios('post', `Common/getUserIds`);
            return res.data.map(row => {
                return row.user_id
            });
        } catch (err) {
            this.showErrorObjectMessage(err);
        }
    }

    getDeviceList = async(hm15_id, hm12_id) => {
        try {
            let res = await this.ascAxios('post', `Common/getDeviceList`, {hm15_id, hm12_id});
            return res.data;
        } catch (err) {
            throw new Error(err);
        }
    }

    getPermissionList = async(hm12_id) => {
        try {
            let res = await this.ascAxios('post', `Common/getPermissionList`, {hm12_id});
            return res.data;
        } catch (err) {
            throw new Error(err);
        }
    }

    getExternalNumberByUser = async(hm15_id) => {
        try {
            let res = await this.ascAxios('post', `Common/getExternalNumberByUser`, {hm15_id});
            return res.data;
        } catch (err) {
            throw new Error(err);
        }
    }

    /**
     * 電話番号入力チェック
     * @param param
     * @returns {boolean} true:OK/false:エラー
     */
    validateTelNumber(param){
        let max = this.props.langText.Value.tel_number.max;
        let min = this.props.langText.Value.tel_number.min;
        let validTelNo = new RegExp(`^[0-9]{${min},${max}}$`);

        return validTelNo.test(param);
    }

    /**
     * メールアドレス入力チェック
     * @param param
     * @returns {boolean} true:OK/false:エラー
     */
    validateMail(param){
        let validMail = new RegExp(/^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+/);
        return validMail.test(param);
    }

    /**
     * パスワード入力チェック
     * @param param
     * @returns {boolean} true:OK/false:エラー
     */
    validatePassword(param){
        let validPW = new RegExp(/(?=.*?[a-z A-Z])(?=.*?[0-9]).{8,}$/);
        return validPW.test(param);
    }

    numberWithCommas = (val) => {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",");
    }

    /**
     * @return {string} 00:00:00
     */
    secToTime = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = Math.floor(secs % 60);

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec
            .toString()
            .padStart(2, "0")}`;
    };
}
