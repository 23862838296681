import React from 'react';
import {Button, Row, Col} from 'react-bootstrap';
import Component from '../../components/AscComponent';
import {EditAccountInfo, ViewAccountInfo} from "../Elements/AscElements/AccountElements";
export default class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hm15_id: 0,
            user_id: '',
            family_name: '',
            first_name: '',
            mail_address: '',
            tel_number: '',
            password: '',
            password_new: '',
            password_new_confirm: '',
            blocking: false,
            subUsers: []
        };
    }
    
    validationHandle = () => {
        let validation_flag;
        let {
            user_id,
            password,
            password_new,
            password_new_confirm,
            tel_number,
            mail_address,
            subUsers
        } = this.state;

        validation_flag = !!(user_id && this.validateMail(mail_address)
            && (password ? this.validatePassword(password) : true)
            && (password_new ? password && this.validatePassword(password_new)
                && password_new_confirm === password_new : true)
            && (tel_number ? this.validateTelNumber(tel_number) : true));

        for (let i = 0; i < subUsers.length; i++) {
            validation_flag = validation_flag && ((!subUsers[i].new && !subUsers[i].password)
                || (subUsers[i].password && this.validatePassword(subUsers[i].password)));
        }

        return validation_flag;
    };

    onClickHandle = pageType => async () => {
        let {
            user_id,
            hm15_id,
            family_name,
            first_name,
            mail_address,
            tel_number,
            password,
            password_new,
            subUsers
        } = this.state;
        try {
            switch (pageType) {
                case 'edit':
                    this.setState({pageType:'view'})
                    break;
                case 'back':
                    this.setState({pageType:'edit'})
                    break;
                case 'view':
                    this.blockUI();
                    await this.ascAxios("post", `${this.reactContainerPath}/update`, {
                        user_id,
                        hm15_id,
                        family_name,
                        first_name,
                        mail_address,
                        tel_number,
                        password,
                        password_new,
                        subUsers
                    });
                    alert(this.props.langText.Message.DataSettingSuccess);
                    this.unblockUI();
                    this.props.historyPush("/");
                    window.location.reload();
                    break;
                default:
                    break;
            }
        } catch (err) {
            this.showErrorObjectMessage(err,'DataSettingError');
            window.location.reload();
        }
    }

    async componentDidMount(){
        try {
            let result = await this.ascAxios("post", `${this.reactContainerPath}/getAccountInfo`);
            this.setState({
                hm15_id: result.data.id,
                user_id: result.data.user_id,
                family_name: result.data.family_name,
                first_name: result.data.first_name,
                mail_address: result.data.mail_address,
                tel_number: result.data.tel_number,
                subUsers: result.data.hm16_sub_users,
                oldSubUsers: [...result.data.hm16_sub_users],
                ht60_total: result.data.ht60_total,
                pageType: 'edit'
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        return (
            <div className='Account'>
                <Row>
                    {/* Conditional rendering based on pageType */}
                    {this.state.pageType === "edit" && (
                        <Col xs={12} md={12}>
                            <EditAccountInfo
                                state={this.state}
                                langText={this.props.langText}
                                onTextChange_Limit={this.onTextChange_Limit}
                                onTextChange={this.onTextChange}
                                charaLimit={this.props.charaLimit}
                                boardWidth={this.props.boardWidth}
                                propSetState={this.propSetState}
                            />
                            {
                                !(window.location.host.includes("first.rec-ace.com")
                                    || window.location.host.includes("first-oa-rec.com")) && (
                                    <div>
                                        <a href="https://lp.rec-ace.com/cancellation-form/" target="_blank"
                                           className="cancel-link"
                                           rel="noopener noreferrer">{this.props.langText.Body.AccountCancelForm}</a>
                                        <Button
                                            id='account-check'
                                            className='table-button account-button'
                                            variant='default'
                                            size='sm'
                                            onClick={this.onClickHandle('edit')}
                                            disabled={!this.validationHandle()}
                                        >
                                            {this.props.langText.Body.Check}
                                        </Button>
                                    </div>
                                )
                            }
                        </Col>
                    )}
                    {this.state.pageType === "view" && (
                        <Col xs={12} md={12}>
                            <ViewAccountInfo
                                state={this.state}
                                langText={this.props.langText}
                                boardWidth={this.props.boardWidth}
                            />
                            <div>
                                <Button
                                    id='account-update'
                                    className='table-button account-button'
                                    variant='default'
                                    size='sm'
                                    onClick={this.onClickHandle('view')}
                                    disabled={!this.validationHandle()}
                                >
                                    {this.props.langText.Body.Set}
                                </Button>
                                <Button
                                    id='account-back'
                                    className='table-button account-button'
                                    variant='default'
                                    size='sm'
                                    onClick={this.onClickHandle('back')}
                                    disabled={!this.validationHandle()}
                                >
                                    {this.props.langText.Body.Cancel}
                                </Button>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        );

    }
}
