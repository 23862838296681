export default {
    DataInsertSuccess: "登録しました。",
    DataUpdateSuccess: "更新しました。",
    DataDeleteSuccess: "削除しました。",
    DataSettingSuccess:"設定しました。",
    
    LogOutConfirm: "ログアウトしますか?",
    LogOut: "ログアウトされました。",

    // エラー
    ModalTypeError:	    "送信情報に不具合があり、実行に失敗しました。",
    AuthCheckError:	    "権限が無いため、実行に失敗しました。",
    DataSelectError:    "取得に失敗しました。",
    DataInsertError:    "登録に失敗しました。",
    DataUpdateError:    "更新に失敗しました。",
    DataDeleteError:    "削除に失敗しました。",
    DataSettingError:   "設定に失敗しました。",
    CompanySelectError:	"会社情報が無いため、情報取得に失敗しました。",
    FindDataError:	    "必要な情報が不足しているため、実行に失敗しました。",
    GetDataError:       "データの取得に失敗しました。管理者に問い合わせください。",
    ExecutionFailedError_Placeholder:        "{0}の実行に失敗しました。管理者にお問い合わせください。",
    ProcessingFailedError: "処理に失敗しました。管理者にお問い合わせください。",
    NoDataDeleteError:    "削除する項目がありません。",
    GetDataError_Server: "サーバーからデータ取得に失敗しました。",
    DeletedModalError:	  "選択した項目はすでに削除されています。",
    PrefixDuplicateError: "プレフィックス {0} はすでに登録されているため、登録に失敗しました。",
    multipleChoiceUpdateError: "複数選択の時は更新できません。",
    NoChoiceDeleteError: "削除する項目を選択してください。",
    NoChoiceUpdateError: "更新する項目を選択してください。",
    RegisteredUserPermissionError: "ユーザーが登録されている権限は削除できません。",
    UpdateModalSelectError: "編集する時には一つだけ選択してください。",
    ModalSelectError: "実行する項目を選択してください。",
    GetS3PreSignedUrl: "音声の取得に失敗しました。管理者に問い合わせください。",
    NotFoundIdentifier: "会社識別子が設定されていません。管理者に問い合わせください。",
    NoUserIdCandidate: "ユーザーID候補が見つかりませんでした。管理者に問い合わせください。",

    // 画面個別設定
    // ユーザー
    User_DataInsertSuccess: "を登録しました。",
    User_InsertRegisteredUserError: "既存ユーザーか過去に登録があるため、登録できませんでした。",
    User_InvalidParameterError: "メールアドレス または パスワード のフォーマットが正しくないため、登録できませんでした。",
    User_UpdateModalSelectError: "編集する時には一つだけ選択してください。",
    User_ModalSelectError: "一つの項目以上選択してください。",
    User_IsLoginedUserError: "は現在ログイン中のユーザーのため、削除できません。",
    User_DeviceOwnError: "選択したデバイスは他のユーザーが所有しているため、登録できません。",
    // デバイス
    Device_IsOwnedDeviceError: "割り当てユーザーがいるため、削除できません。",

    SignIn_VerifyLink: "ユーザー認証はこちら",
    SignIn_ResetPasswordLink: "パスワードをお忘れの方はこちら",
    SignIn_SignInFailed: "ユーザーIDまたはパスワードが違います。",
    NotAuthorizedException: "パスワードが違います。",
    SignIn_NotMatchCodeOrExpiredError: "入力されたコードは間違っているか有効期限が切れています。",
    SignIn_NotMatchCodeError: "入力されたコードが間違っています。",
    SignIn_ExecutionFailedError: "実行に失敗しました。",
    SignIn_PasswordResetError: "パスワードリセットに失敗しました。\nメールアドレスや認証コードを再確認してください。",
    SignIn_UserNotFoundError: "入力されたメールアドレスは登録されていません。",
    SignIn_TooMuchFailsError: "認証エラーの回数が上限を超えました。再度コードを発行してください。",
    SignIn_NotConfirmedUserError: "未認証のユーザーです。ユーザー認証を行ってください。",
    SignIn_VerifySuccess: "認証に成功しました。",
    SignIn_SentVerificationCode: "認証コードを送信しました。",
    SignIn_ResetPasswordSuccess: "パスワードの再設定に成功しました。",
    SignIn_ConfirmResend: "再送信しますか？",
    NoDepartmentError: "所属している拠点またはフロアがありません。\n管理者に所属変更依頼を出してください。",
    SignIn_NoExistMailAlert1: "パスワード再設定用の認証コードをメールアドレスに送信します。",
    SignIn_NoExistMailAlert2: "使用しているメールアドレスがメールを受信出来ることを確認して下さい。",
    SignIn_ResetPasswordExpireMessage: "認証コードは１時間以内に入力してください。",

    // 権限管理
    Permission_BasePermissionDeleteError: "基本権限のため削除できません。",
    Permission_UserCountDeleteError: "利用ユーザーがいる場合は削除できません。",

    UserNotFoundException:"ユーザーが存在しません。システム管理者までお問合せください。",
    TooManyRequestsException:"リクエストが多かったため、削除ができませんでした。後でお試しください。",
    UserNotConfirmedException:"確認されなかったアカウントです。",

    //音声一覧
    Record_CustomerDelete:"電話帳から削除します。",
    Record_VoiceDeleted: "削除",
    Record_VoiceDelete: "録音音声を削除します。",
    Keyword: "キーワード",
    Creating: "を作成する(EnterまたはTabで決定)",

    // Validation
    Validation_TextSearch: `AI文字起こしから検索`,
    Validation_string_input: `文字を入力してください。`,
    Validation_Number_input: `数字を入力してください。`,
    Validation_Select_input: `項目を選んでください。`,
    Validation_File_input: `ファイルを選んでください。`,
    SignIn_UserNotFoundException:"ユーザーが存在しません。システム管理者までお問合せください。",
    PasswordResetRequiredException:"パースワードがリセットされました。システム管理者までお問合せください。",
    Validation_Telno: `電話番号を入力してください。`,
    Validation_Record_System: `ユーザーIDを入力してください。`,
    Validation_DateTime: `検索終了日時を検索開始日時より大きく設定してください。`
}
