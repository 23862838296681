import React from "react";
import Component from "../../../components/AscComponent";
import {Button, Container, Navbar, OverlayTrigger, Popover} from "react-bootstrap";
import "./Header.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import headerLogo from "../../../image/header-logo.png";

export default class Header extends Component {

    handleMouseOver = event => {
        this.setState({disabled: false});
    };

    handleMouseOut = event => {
        this.setState({disabled: true});
    };

    handleLogout = async event => {
        if (window.confirm(this.props.childProps.langText.Message.LogOutConfirm)) {
            this.props.childProps.userHasAuthenticated(false);
            this.props.childProps.historyPush("/SignIn");
            await this.ascAxios("post", "/Sign/logout");
            alert(this.props.childProps.langText.Message.LogOut);
        }
    };

    getMenuList = () => {
        let permissionList = this.props.childProps.userInfo.permission_data;
        let HeaderArr = Object.keys(this.props.childProps.langText.Header);

        let menuItemArr = [];
        for(let feature of Object.keys(permissionList)){
            if (HeaderArr.find(ele => ele === feature) && permissionList[feature].read === true) {
                menuItemArr.push(
                    <a key={feature} href={"/"+feature} className="header-menu">{this.props.childProps.langText.Header[feature]}</a>
                );
                menuItemArr.push(
                    <hr key={feature+"hr"}/>
                );
            }
        }

        return menuItemArr;
    }

    getUserInfo = () => {
        let userInfo = this.props.childProps.userInfo;

        return <>
            <div>
                {userInfo.sub_user_id ? this.props.childProps.langText.Body.AccountSubUserId : this.props.childProps.langText.Body.UserId}
                : {userInfo.sub_user_id ? userInfo.sub_user_id : userInfo.user_id}
            </div>
            <hr key={"id-hr"}/>
            {userInfo.sub_user_id ? null : <div>
                <a key='account' href={"/Account"}>{this.props.childProps.langText.Header.Account}</a>
            </div>}
            {userInfo.sub_user_id ? null : <hr key={"account-hr"}/>}
        </>;
    }

    render() {
        let popover =
            <Popover id="popover-basic">
                <Popover.Header as="h3">{this.props.childProps.systemParameters.SYSTEMTITLE}</Popover.Header>
                <Popover.Body>
                    {this.getUserInfo()}
                    {this.getMenuList()}
                    <div className="logout-button-div">
                        <Button variant="danger" className="logout-button" onClick={this.handleLogout}>{this.props.childProps.langText.Body.LogOut}</Button>
                    </div>
                </Popover.Body>
            </Popover>
        return (
            <Navbar collapseOnSelect expand="lg">
                <Container fluid>
                <Navbar.Brand href="/">
                    <img alt = {this.props.childProps.systemParameters.SYSTEMTITLE} src = {this.props.childProps.systemParameters.HEADERLOGO}/>
                </Navbar.Brand>
                <OverlayTrigger rootClose="true" trigger="click" placement="left" overlay={popover}>
                    <Button variant="outline-secondary" className="toggle-btn"><FontAwesomeIcon icon={faBars}/></Button>
                </OverlayTrigger>
                </Container>
            </Navbar>
        );
    }
}
