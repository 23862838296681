import React from "react";
import {Form} from "react-bootstrap";
import InlineForm from "../FromGroup/InlineForm";
import Component from "../../../components/AscComponent";
import CommonTable from "../Table/CommonTable";
import * as GlobalConst from "../../../components/AscConstants";


/**
 * 権限名入力アイテム
 * state.permission_nameが必要
 */
export class EditPermissionName extends Component {

    render() {
        return (
            <InlineForm
                key = "permission_name"
                controlId = "permission_name"
                label = {this.props.langText.Body.PermissionName}
                type = "text"
                value = {this.props.state.permission_name}
                onChange = {e => this.props.onTextChange_Limit(
                    e, "permission_name",this.props.charaLimit.Permission_permissionName)}
                validationstate = {this.props.state.permission_name ? null : "error"} />
        );
    }
}

export class EditPermissionMemo extends Component {

    render() {
        return (
            <InlineForm
                key = "permission_memo"
                controlId = "permission_memo"
                label = {this.props.langText.Body.PermissionMemo}
                type = "text"
                value = {this.props.state.permission_memo}
                onChange = {e => this.props.onTextChange_Limit(
                    e, "permission_memo",this.props.charaLimit.Permission_permissionMemo)}
                validationstate = {this.props.state.permission_memo ? null : "error"} />
        );
    }
}

/**
 * 権限名表示アイテム
 * state.permission_nameが必要
 */
export class ViewPermissionName extends Component {

    render() {
        return (
            <InlineForm
                key="permission_name"
                controlId="permission_name"
                label={this.props.langText.Body.PermissionName}
                readOnly
                children={this.props.state.permission_name}>
            </InlineForm>
        )
    }
}

/**
 * ユーザー数表示アイテム
 * state.user_countが必要
 */
export class ViewPermissionUserCount extends Component {
    render() {
        return (
            <InlineForm
                key="user_count"
                controlId="user_count"
                label={this.props.langText.Body.UserCount}
                readOnly
                children={this.props.state.user_count}>
            </InlineForm>
        )
    }
}

/**
 * 権限詳細表示アイテム
 * state[controller_id]が必要
 */
export class ViewPermissionInfo extends Component {

    render() {
        let modal_columns = [
            {
                Header :this.props.langText.Body.PermissionName,
                accessor: "SettingColumn_PermissionName",
            },
            {
                Header :this.props.langText.Body.PermissionMemo,
                accessor: "SettingColumn_PermissionMemo",
            },
            {
                Header :this.props.langText.Body.UserCount,
                accessor: "SettingColumn_PermissionUserCount",
            },
        ]
        let returnValue =[];

        this.props.state.selectedData.forEach(row => {
            returnValue.push(
                {
                    SettingColumn_PermissionName: 
                        row['name'],
                    SettingColumn_PermissionMemo:
                        row['memo'],
                    SettingColumn_PermissionUserCount:
                        row['hm15_user'] ? row['hm15_user']['user_count'] : 0,
                }
            )
        });

        return (
            <InlineForm
                key = "permission_detail"
                controlId = "permission_detail"
                label = {this.props.langText.Body.permissionDetail}>
                {this.props.state.registered_user_flag &&
                    <p key="RegisteredUserPermissionError" className="text-danger">
                    {this.props.langText.Message.RegisteredUserPermissionError}
                    </p>}
                <CommonTable
                    defaultPageSize={10}
                    showPagination = {true}
                    columns = {modal_columns}
                    data = {returnValue}
                    pageSize = {returnValue.length}
                    sortable = {false}
                />
            </InlineForm>
        );
    }
}

/**
 * 権限詳細入力アイテム
 * state[controller_id]が必要
 */
export class EditPermissionDetail extends Component {

    render() {
        let modal_columns = [
            {
                Header :this.props.langText.Body.PermissionFeature,
                accessor: "SettingColumn_PermissionFeature",
                width: this.props.boardWidth.medium,
            },
            {
                Header :this.props.langText.Body.PermissionLevel,
                accessor: "SettingColumn_PermissionLevel",
                width: this.props.boardWidth.medium,
            },
            {
                Header :this.props.langText.Body.PermissionRead,
                accessor: "SettingColumn_PermissionRead",
                width: this.props.boardWidth.xxxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionCreate,
                accessor: "SettingColumn_PermissionCreate",
                width: this.props.boardWidth.xsmall,
            },
            {
                Header :this.props.langText.Body.PermissionEdit,
                accessor: "SettingColumn_PermissionUpdate",
                width: this.props.boardWidth.xxxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionDelete,
                accessor: "SettingColumn_PermissionDelete",
                width: this.props.boardWidth.xxxsmall,
            },
            {
                Header :this.props.langText.Body.Download,
                accessor: "SettingColumn_PermissionDownload",
                width: this.props.boardWidth.small,
            },
            {
                Header :this.props.langText.Body.PlayBack,
                accessor: "SettingColumn_PermissionPlayback",
                width: this.props.boardWidth.xsmall,
            }
        ]
        let featureList = this.getSelectOption("feature", this.props.langText.SelectOption);
        if (this.props.permission.level !== "system") {
            featureList = featureList.filter(row => row.value !== "system")
        }
        let returnValue =[];
        GlobalConst.CONTROLLER_LIST.forEach(row => {
            returnValue.push(
                {
                    SettingColumn_PermissionFeature: 
                        this.props.langText.Body.PermissionContentList[row],
                    SettingColumn_PermissionLevel:
                        <select
                            value = {this.props.state[row].level}
                            onChange = {e => this.props.onNestSetState(e, row, "level")} >
                            {featureList.map(feature_row =>
                                <option key={feature_row.label} value={feature_row.value}>{feature_row.label}</option>)}
                        </select>,
                    SettingColumn_PermissionRead:
                        <Form.Check checked={this.props.state[row].read}
                                    onChange={e => this.props.onNestCheckBoxChange(e, row, "read")} />,
                    SettingColumn_PermissionCreate:
                        <Form.Check checked={this.props.state[row].create}
                                    onChange={e => this.props.onNestCheckBoxChange(e, row, "create")} />,
                    SettingColumn_PermissionUpdate:
                        <Form.Check checked={this.props.state[row].update}
                                    onChange={e => this.props.onNestCheckBoxChange(e, row, "update")} />,
                    SettingColumn_PermissionDelete:
                        <Form.Check checked={this.props.state[row].delete}
                                    onChange={e => this.props.onNestCheckBoxChange(e, row, "delete")} />,
                    SettingColumn_PermissionDownload:
                        <Form.Check checked={this.props.state[row].download}
                                    onChange={e => this.props.onNestCheckBoxChange(e, row, "download")} />,
                    SettingColumn_PermissionPlayback:
                        <Form.Check checked={this.props.state[row].playback}
                                    onChange={e => this.props.onNestCheckBoxChange(e, row, "playback")} />
                }
            )
        });
        return (
            <InlineForm
                key = "permission_detail"
                controlId = "permission_detail"
                label = {this.props.langText.Body.permissionDetail}>
                <CommonTable
                    label={this.props.langText.Body.PermissionName}
                    columns={modal_columns}
                    data={returnValue}
                    pageSize={returnValue.length}
                    sortable={false}
                    state={this.state}
                    style={{height: "200px"}}
                />
            </InlineForm>
        );
    }
}


/**
 * 権限詳細入力アイテム
 * state[controller_id]が必要
 */
export class ViewPermissionDetail extends Component {

    render() {
        let modal_columns = [
            {
                Header :this.props.langText.Body.PermissionFeature,
                accessor: "SettingColumn_PermissionFeature",
                width: this.props.boardWidth.medium,
            },
            {
                Header :this.props.langText.Body.PermissionLevel,
                accessor: "SettingColumn_PermissionLevel",
                width: this.props.boardWidth.medium,
            },
            {
                Header :this.props.langText.Body.PermissionRead,
                accessor: "SettingColumn_PermissionRead",
                width: this.props.boardWidth.xxxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionCreate,
                accessor: "SettingColumn_PermissionCreate",
                width: this.props.boardWidth.xsmall,
            },
            {
                Header :this.props.langText.Body.PermissionEdit,
                accessor: "SettingColumn_PermissionUpdate",
                width: this.props.boardWidth.xxxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionDelete,
                accessor: "SettingColumn_PermissionDelete",
                width: this.props.boardWidth.xxxsmall,
            },
            {
                Header :this.props.langText.Body.Download,
                accessor: "SettingColumn_PermissionDownload",
                width: this.props.boardWidth.small,
            },
            {
                Header :this.props.langText.Body.PlayBack,
                accessor: "SettingColumn_PermissionPlayback",
                width: this.props.boardWidth.xsmall,
            }
        ]
        let featureList = this.getSelectOption("feature", this.props.langText.SelectOption);
        let returnValue =[];
        GlobalConst.CONTROLLER_LIST.forEach(row => {
            returnValue.push(
                {
                    SettingColumn_PermissionFeature:
                        this.props.langText.Body.PermissionContentList[row],
                    SettingColumn_PermissionLevel:
                        <InlineForm >
                            {featureList.find(item => item.value === this.props.state[row].level).label}
                        </InlineForm>,
                    SettingColumn_PermissionRead:
                        <Form.Check checked={this.props.state[row].read}/>,
                    SettingColumn_PermissionCreate:
                        <Form.Check checked={this.props.state[row].create}/>,
                    SettingColumn_PermissionUpdate:
                        <Form.Check checked={this.props.state[row].update}/>,
                    SettingColumn_PermissionDelete:
                        <Form.Check checked={this.props.state[row].delete}/>,
                    SettingColumn_PermissionDownload:
                        <Form.Check checked={this.props.state[row].download}/>,
                    SettingColumn_PermissionPlayback:
                        <Form.Check checked={this.props.state[row].playback}/>
                }
            )
        });
        return (
            <InlineForm
                key = "permission_detail"
                controlId = "permission_detail"
                label = {this.props.langText.Body.permissionDetail}>
                <CommonTable
                    label={this.props.langText.Body.PermissionName}
                    columns={modal_columns}
                    data={returnValue}
                    pageSize={returnValue.length}
                    sortable={false}
                    state={this.state}
                    style={{height: "200px"}}
                />
            </InlineForm>
        );
    }
}