import React, {Component} from "react";
import {Card} from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import {ViewUserId, EditUserName, EditMailAddress, EditPassword, SelectPermission,
    SelectDevice, EditTelNum, EditExternalNum, ViewType, ViewExpire, SelectOptions
} from "../Elements/AscElements/UserElements";



export default class UserUpdateModal extends Component {
    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Card variant="info">
                    <Card.Header>
                        <Card.Title>
                            {this.props.langText.Body.UserInfo}
                        </Card.Title>
                    </Card.Header>

                    <Card.Body>
                        <ViewUserId
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <EditPassword
                            state={this.props.state}
                            userInfo={this.props.userInfo}
                            langText={this.props.langText}
                            propSetState={this.props.propSetState}
                            onTextChange = {this.props.onTextChange}
                            validatePassword = {this.props.validatePassword}
                        />
                        <EditUserName
                            state={this.props.state}
                            langText={this.props.langText}
                            onTextChange_Limit = {this.props.onTextChange_Limit}
                            charaLimit = {this.props.charaLimit}
                        />
                        <ViewType
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewExpire
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <EditMailAddress
                            state={this.props.state}
                            langText={this.props.langText}
                            onTextChange_Limit = {this.props.onTextChange_Limit}
                            charaLimit = {this.props.charaLimit}
                            validateMail = {this.props.validateMail}
                        />
                        <EditTelNum
                            state={this.props.state}
                            langText={this.props.langText}
                            onTextChange_Limit = {this.props.onTextChange_Limit}
                            charaLimit = {this.props.charaLimit}
                            validateTelNumber = {this.props.validateTelNumber}
                        />
                        <EditExternalNum
                            state={this.props.state}
                            langText={this.props.langText}
                            onMultiSelectChange_External={this.props.onMultiSelectChange_External}
                        />
                        <SelectPermission
                            state={this.props.state}
                            userInfo={this.props.userInfo}
                            langText={this.props.langText}
                            onSelectChange={this.props.onSelectChange}
                        />
                        <SelectDevice
                            state={this.props.state}
                            langText={this.props.langText}
                            onMultiSelectChange={this.props.onMultiSelectChange}
                        />
                        <SelectOptions
                            state={this.props.state}
                            langText={this.props.langText}
                            propSetState={this.props.propSetState}
                        />
                    </Card.Body>
                </Card>
            </AscModal>
        );
    }
}
