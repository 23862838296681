import React, {Component} from "react";
import {Card} from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import {
    ViewUserId, ViewMailAddress, ViewUserName, ViewType, ViewExpire, ViewTelNum, ViewExternalNum, ViewPermission, ViewDevice
} from "../Elements/AscElements/UserElements";

export default class UserReadModal extends Component {

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Card variant="info">
                    <Card.Header>
                        <Card.Title>
                            {this.props.langText.Body.UserInfo}
                        </Card.Title>
                    </Card.Header>

                    <Card.Body>
                        <ViewUserId
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewUserName
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewType
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewExpire
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewMailAddress
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewTelNum
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewExternalNum
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewPermission
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                        <ViewDevice
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                    </Card.Body>
                </Card>
            </AscModal>
        );
    }
}
