import React from "react";
import {Row, Col} from "react-bootstrap";
import ReactLoading from 'react-loading';
import Component from "./components/AscComponent";
import {withRouter} from "react-router-dom";
import Routes from "./AscRoutes";
import Header from "./containers/Elements/Header/Header";
import "./App.css";
import Gitversion from './GitVersion.json';
import loginImg from "./image/signin-bg.jpg";


const tryRequire = path => {
    try {
        return require(path);
    } catch (e) {
        return null;
    }
};

class App extends Component {
    constructor(props) {
        super(props);

        // ヘッダー部の高さを固定
        let headerHeight = 71

        this.state = {
            lang: "ja",
            isAuthenticated: false,
            isAuthenticating: true,
            headerHeight: headerHeight,
            width: window.innerWidth,
            appHeight: window.innerWidth < 768 ? "100%" : window.innerHeight - headerHeight,
            tableHeight: window.innerHeight - headerHeight - 150,
            userInfo: {},
            systemParameters: {},
        }
        this.getSystemParameterByType()
    }

    
    /**
     * システムパラメータ情報取得
     * @param param
     * @returns {json}
     */
    getSystemParameterByType = async() => {
        try {
            let url = window.location.host;
            let url_parm = url.split('.').slice(-3).join('.');
            let res = await this.ascAxios('post', `Sign/getSystemParameterByType`, {parameter_type: url_parm});
            this.setState({systemParameters: res.data});
        } catch (err) {
            throw new Error(err);
        }
    }

    getMyPath = () => {
        return this.props.location.pathname;
    }

    userHasAuthenticated = (authenticated, userInfo) => {
        let isAuthenticated = authenticated;
        userInfo = userInfo || {};

        this.setState({
            isAuthenticated,
            userInfo
        });
    };

    authenticating = authenticating => {
        this.setState({isAuthenticating: authenticating});
    };

    historyPush = (target) => {
        this.props.history.push(target);
    };

    async componentDidMount() {
        try {
            let response = await this.ascAxios("post", "/Sign/check");
            if (response && response.data && response.data.user_id) {
                this.userHasAuthenticated(true, response.data);
            } else {
                this.historyPush("/SignIn");
            }
        } catch (e) {
            console.error(e);
        }

        this.authenticating(false);
    }

    getMyLangText = (lang) => {
        let langArr = [
            "Body",
            "Message",
            "Header",
            "SelectOption",
            "Table",
            "Time",
            "Value"
        ];

        let langObj = {};

        langArr.forEach(row => {
            langObj[row] = tryRequire(`./lang/${lang}/Elements/${row}`) ? tryRequire(`./lang/${lang}/Elements/${row}`).default : require(`./lang/ja/Elements/${row}`).default;
        });

        return langObj;
    }
    getCharaLimit = () => {
        let limitObj = [];
        limitObj = require(`./chara_limit`).default;
        return limitObj;
    }

    getCurrentPermission = () => {
        if (this.state.userInfo.permission_data) {
            for(let feature of Object.keys(this.state.userInfo.permission_data)){
                let path = this.props.location.pathname.split("/")[1];
                if (feature.toLowerCase() === path.toLowerCase()) {
                    return this.state.userInfo.permission_data[feature];
                }
            }
        }
        return {};
    }

    render() {
        let childProps = {
            currentPermission: this.getCurrentPermission(),
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            historyPush: this.historyPush,
            lang: this.state.lang,
            width: this.state.width,
            userInfo: this.state.userInfo,
            getMyPath: this.getMyPath,
            langText: this.getMyLangText(this.state.lang),
            charaLimit: this.getCharaLimit(),
            tableHeight: this.state.tableHeight,
            boardWidth: {
                xxxsmall: 50,
                xxsmall: 60,
                xsmall: 80,
                small: 100,
                smedium: 120,
                medium: 140,
                large: 160,
                xlarge: 180,
                Control: 90,
                Date: 100,
                CallNumber: 120,
                Name: 120,
            },
            systemParameters: this.state.systemParameters
        };

        let style =
        {
            backgroundImage: window.innerWidth < 768 ? `url(${loginImg})`: `url(${loginImg})`,
        };
        let appContent = <>
        <div className="signin-container">
                <Col xs={12} sm={12} className="signIn-logo-overview" style={style}>
                    
                    <Routes childProps={childProps}/>
                </Col>
                <div className="git-version">
                    {Gitversion.date}<br/>
                    Ver.{Gitversion.version}
                </div>
            </div>
        </>


        let permissionList = this.state.userInfo.permission_data;
        let PermissionArr = [];

        if(permissionList){
            PermissionArr = Object.values(permissionList);
        }
        let emailVerfiedFlag = this.state.userInfo.user_mail_address;
        if (this.state.isAuthenticated) {
            if (!emailVerfiedFlag){
                this.props.location.pathname = '/Account';
            } else{
                switch (this.props.location.pathname) {
                    case "/Forbidden":
                        break;
                    case "/Account":
                        break;
                    case "/SignIn":
                    case "/":
                        // ログイン直後もしくは初期画面は最初にread権限があるコンテナへ(一つもなければforbiddenへ)
                        if (PermissionArr.map(row => row.read).filter(value => value).length === 0) {
                            this.props.location.pathname = "/Forbidden";
                            alert(childProps.langText.Message.AuthCheckError);
                        } else {
                            let pathArr = [];
                            if(PermissionArr){
                                PermissionArr.forEach((row,index) => {
                                    if(row.read === true){
                                        pathArr.push(Object.keys(permissionList)[index]);
                                    }
                                });
                            }
                            this.props.location.pathname = "/" + pathArr[0];
                            // ログイン直後の権限セット
                            childProps.currentPermission = this.getCurrentPermission();
                        }
                        break;
                    default:
                        // アクセスコンテナのread権限が無い場合はforbidden(権限が存在しない場合{}も)
                        if (!childProps.currentPermission.read) {
                            this.props.location.pathname = "/Forbidden";
                            alert(childProps.langText.Message.AuthCheckError);
                        }
                        break;
                }
            }
            appContent = <>
                <Row className="row-upper">
                    <Col xs={12} md={12} id="header-app" className="header-app Header">
                        <Header childProps={childProps}/>
                    </Col>
                </Row>
                <Row className="row-lower">
                    <Col xs={12} sm={12} id="routes-app" className="routes-app" style={{height: this.state.appHeight}}>
                        <Routes childProps={childProps}/>
                    </Col>
                </Row>
            </>;
        }

        return (
            !this.state.isAuthenticating ?
            <div className = "App">
                {appContent}
                <div id="asc-block-ui" className="asc-block-ui asc-display-none z-index-1500">
                    <div className="asc-block-icon-box">
                        <ReactLoading className="asc-block-icon" type="spinningBubbles" color="#fff" />
                        <label className="asc-block-label">{childProps.langText.Body.Wait}</label>
                    </div>
                </div>
            </div>
            :
            <div className = "App">
                <div className="asc-block-ui">
                    <div className="asc-block-icon-box">
                        <ReactLoading className="asc-block-icon" type="spinningBubbles" color="#fff" />
                        <label className="asc-block-label">{childProps.langText.Body.Wait}</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(App);
