import React, {Component} from "react";
import {Card} from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import {ViewDeleteDeviceList} from "../Elements/AscElements/DeviceElements";


export default class DeviceDeleteModal extends Component {

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Card variant="info">
                    <Card.Header>
                        <Card.Title>
                            {this.props.langText.Body.DeviceInfo}
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ViewDeleteDeviceList
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                    </Card.Body>
                </Card>
            </AscModal>
        );
    }
}
