// item
import React from "react";
import {Route, Switch} from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";

// controller
import SignIn from "./containers/SignIn/SignIn";
import Permission from "./containers/Permission/Permission";
import User from "./containers/User/User";
import Record from "./containers/Record/Record";
import Device from "./containers/Device/Device";
import Forbidden from "./containers/Forbidden/Forbidden";
import Account from "./containers/Account/Account";


/**
 * コントローラー追加したらsrc/components/AscConstants.jsにも記載すること
 * @param childProps
 * @returns {JSX.Element}
 */
export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/SignIn" exact component={SignIn} props={childProps}/>
        <AppliedRoute path="/Permission" exact component={Permission} props={childProps}/>
        <AppliedRoute path="/User" exact component={User} props={childProps}/>
        <AppliedRoute path="/Device" exact component={Device} props={childProps}/>
        <AppliedRoute path="/Account" exact component={Account} props={childProps}/>
        <AppliedRoute path="/Record" exact component={Record} props={childProps}/>
        <Route component={Forbidden}/>
    </Switch>
