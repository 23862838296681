import React, {Component} from "react";
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import "./Modal.css";
import ReactLoading from "react-loading";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
export default class AscModal extends Component {
    // get modal title
    getTitle = (type) => {
        let returnObj = {title: null, okBtnDisplayFlg: true};

        switch (this.props.state.modalType) {
            case "insert":
            case "create":
                returnObj.title = (type === "title") ? this.props.langText.Body.InsertTitle : this.props.langText.Body.Insert;
                break;
            case "update":
                returnObj.title = (type === "title") ? this.props.langText.Body.UpdateTitle : this.props.langText.Body.Update;
                break;
            case "delete":
                returnObj.title = (type === "title") ? this.props.langText.Body.DeleteTitle : this.props.langText.Body.Delete;
                break;
            case "read":
                returnObj.title = this.props.langText.Body.ReadTitle;
                returnObj.okBtnDisplayFlg = false;
                break;
            case "setting":
                returnObj.title = this.props.langText.Body.Set;
                break;
            case "playback":
                returnObj.title = this.props.langText.Body.PlayBack;
                returnObj.okBtnDisplayFlg = false;
                if (this.props.currentPermission.delete) {
                    returnObj.customDeleteBtn = true;
                }
                break;
            default:
        }

        return returnObj;
    }

    customDLBtn = () => {
        let result = this.getTitle("ok");
        if (result.okBtnDisplayFlg) {
            return (
                <>
                <Button
                    className = 'ok-execute'
                    onClick = {this.props.onClick(this.props.state.modalType)}
                    variant = {this.props.state.modalType !== "delete" ? "primary" : "danger"}
                    disabled = {!this.props.validationHandle(this.props.state.modalType)}>
                    {result.title}
                </Button>
                {this.props.state.DownloadData}
                </>
            );
        } else {
            return null;
        }
    }

    getOkBtn = () => {
        let result = this.getTitle("ok");
        if (result.okBtnDisplayFlg) {
            return (
                <Button
                    className = 'ok-execute'
                    onClick = {this.props.onClick(this.props.state.modalType)}
                    variant = {this.props.state.modalType !== "delete" ? "primary" : "danger"}
                    disabled = {!this.props.validationHandle(this.props.state.modalType)}>
                    {result.title}
                </Button>
            );
        } else {
            return null;
        }
    }

    getCancelBtn = () => {
        return (
            <Button
                className = 'cancel-execute'
                onClick = {e => this.props.propSetState({show: false})}
                variant = 'outline-secondary'
            >
                {this.props.langText.Body.Cancel}
            </Button>
        );
    }

    getXBtn = () => {
        return (
            <Button
                className = 'x-execute'
                onClick = {e => this.props.propSetState({show: false})}
            >
                <FontAwesomeIcon icon={faTimes}/>
            </Button>
        );
    }

    customDeleteBtn () {
        return (
            <Button
                className = "custom-delete"
                onClick = {e => this.props.onClick("voiceDelete")}
            >
                {this.props.langText.Body.Delete}
            </Button>
        );
    }

    modalLoadingDisplay = () => {
        if (this.props.state.loadingDisplayFlag) {
            return(
                <div className="asc-block-ui">
                    <div className="asc-block-icon-box">
                        <ReactLoading className="asc-block-icon" type="spinningBubbles" color="#fff" />
                        <label className="asc-block-label">{this.props.langText.Table.LoadingText}</label>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }

    render() {
        let {
            children,
            state,
            downloadFlag,
            currentPermission
        } = this.props;
        let result = this.getTitle("title");

        return (
            <Modal
                dialogClassName = {this.props.dialogClassName}
                scrollable = "true"
                show = {state.show}
                size = "lg"
                aria-labelledby = "contained-modal-title-lg"
            >
                <Form horizontal="true">
                    {this.modalLoadingDisplay()}
                    <Modal.Header>
                        <Modal.Title>
                            <Row>
                                <Col className="modal-title-text" lg={11} sm={11} xs={11}>
                                    {result.title}
                                </Col>
                                <Col lg={1} sm={1} xs={1}>
                                    <span>{this.getXBtn()}</span>
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {children}
                    </Modal.Body>

                    <Modal.Footer className="card-button">
                        <div className="child-box align-left">
                            {currentPermission && currentPermission.delete ? <span>{this.customDeleteBtn()}</span>: ""}
                        </div>
                        <div className="child-box align-right">
                            <span>{this.getCancelBtn()}</span>
                            {downloadFlag
                                ?<span>{this.customDLBtn()}</span>
                                :<span>{this.getOkBtn()}</span>
                            }
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
