import React from "react";
import "./CommonTable.css";
import {Button} from 'react-bootstrap';
import { useTable, usePagination, useRowSelect, useFilters} from "react-table";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
        <input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
        </>
    );
    }
);

function DefaultColumnFilter({
    column: { filterValue, setFilter },
    }) {

    return (
        <input
            className="filter-input"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        />
    )
}

function Table( {columns, data, state, loading, pageCount: controlledPageCount, propsPageIndex, ...props} ){
    const filterTypes = React.useMemo(
        () => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
        }}), []
    )

    const defaultColumn = React.useMemo(
        () => ({
        Filter: DefaultColumnFilter,
        }), []
    )

    const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: {pageIndex, pageSize}
    } = useTable({
    columns,
    data,
    initialState: { pageSize: props.defaultPageSize},
    defaultColumn,
    filterTypes,
    manualPagination: props.manual,
    autoResetPage: propsPageIndex? false: true,
    pageCount: controlledPageCount,
    },  
    useFilters,
    usePagination,
    useRowSelect,
    (hooks) => {
    (props.showCheckbox &&
        hooks.visibleColumns.push((columns) => [
            {
            id: "selection",
            width: "20px",
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
            ),
            Cell: ({ row }) => (
                <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
            )
            },
            ...columns
        ]));
    })
    
    if(state && state.selectedData && props.showCheckbox){
        state.selectedData = [];
        selectedFlatRows.map(
            d => state.selectedData.push(d.original)
        );
    }

    React.useEffect(() => {
        if(props.onFetchData) props.onFetchData(pageSize, pageIndex, props.sorted, props.filtered)
    }, [props.onFetchData, pageSize, pageIndex, props.sorted, props.filtered])

    return (
        <>
            <div className={"table-div " + (props.className||"")} style={{maxHeight: props.tableHeight}}>
            <table {...getTableProps()} className="common-table">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            
                        <th {...column.getHeaderProps()} style={{width:column.width}}>
                            {column.render("Header")}
                            {props.showFilter&&
                                <div >{column.canFilter ? column.render('Filter') : null}</div>
                            }
                        </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{maxHeight: props.tbodyHeight}}>
                {page.map((row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                        return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        );
                    })}
                    </tr>
                );
                })}
            </tbody>
            </table>
            {
                loading && !rows.length
                ? <div className="table-loading">{props.langText.LoadingText}</div>
                : (!rows.length
                    ? <div className="no-data-text">{props.langText.NoDataText}</div>
                    : null)
            }
            </div>
            {
                props.showPagination &&
                <div className="pagination">
                    <Button variant="outline-secondary" className="page-btn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"<<"}
                    </Button>
                    <Button variant="outline-secondary" className="page-btn" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}
                    </Button>
                    <span className="page-span">
                        <strong>
                        <input
                            className="page-input"
                            type="number"
                            value={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: "100px" }}
                        /> 
                        / {pageOptions.length}
                        </strong>
                    </span>
                    {
                        props.showPageSize&&
                        <select
                            value={pageSize}
                            onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            }}
                        >
                            {[5, 10, 20, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}{props.langText.RowsText}
                            </option>
                            ))}
                        </select>
                    }
                    
                    <Button variant="outline-secondary" className="page-btn" onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}
                    </Button>
                    <Button variant="outline-secondary" className="page-btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {">>"}
                    </Button>
                </div>
            }
        </>
    )
}
export default ({
                    columns,
                    data,
                    defaultPageSize = 20,
                    page,
                    ...props
                }
                ) => {
    
    return (
        <Table columns={columns}
                data ={data} 
                page={page}
                defaultPageSize={defaultPageSize}
                state = {props.state}
                pageCount={props.pageCount}
                loading = {props.loading}
                manualPagination = {props.manual}
                {...props} />
    )
};